import { enableProdMode } from '@angular/core';
import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl()
{
	return environment.baseUrl;
}

const providers =
	[
		{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
	];

if (environment.production)
{
	enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
	.catch(err => console.log(err));