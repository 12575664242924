import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeEN from '@angular/common/locales/en';
import localeENExtra from '@angular/common/locales/extra/en';
import localeITExtra from '@angular/common/locales/extra/it';
import localeIT from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from "@angular/core";
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as R from 'ramda';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppComponent } from "./app.component";
import { PCodeConfig } from './app.config';
import { AppRoutingModule } from "./app.routing";
import { GlobalState } from "./app.state";
import { AuthService } from './shared/services/authentication/auth.service';
import { IdentityService } from './shared/services/authentication/identity.service';
import { NotificationsService } from './shared/services/notifications/notifications.service';

// localization
const USER_LOCALE = localStorage.getItem('user_lang') || 'it';
export class MultiTranslateHttpLoader implements TranslateLoader
{
	constructor(private http: HttpClient, public resources: { prefix: string; suffix: string }[] = [{ prefix: '/assets/translate/', suffix: '.json' }]) { }

	public getTranslation(lang: string)
	{
		return forkJoin(this.resources.map(config =>
		{
			return this.http.get(config.prefix + lang + config.suffix);
		}))
			.pipe(map(response =>
			{
				return (response as [][]).reduce((a, b) =>
				{
					return R.mergeDeepLeft(a, b);
				});
			}));
	}
}
export function HttpLoaderFactory(http: HttpClient)
{
	return new MultiTranslateHttpLoader(http,
		[
			{ prefix: "/assets/translate/webapp/", suffix: ".json" }
		]);
}
registerLocaleData(localeIT, localeITExtra);
registerLocaleData(localeEN, localeENExtra);

// azure ad authentication function
function MSALInstanceFactory(): IPublicClientApplication
{
	return new PublicClientApplication({
		auth:
		{
			clientId: PCodeConfig.azureAd.clientId,
			authority: `https://login.microsoftonline.com/${PCodeConfig.azureAd.tenantId}`
		},
		cache:
		{
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: (window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1),
		}
	});
}

// module
@NgModule({
	declarations: [AppComponent],
	imports:
		[
			AppRoutingModule,
			BrowserModule,
			BrowserAnimationsModule,
			HttpClientModule,
			JwtModule.forRoot(
				{
					config:
					{
						tokenGetter: AuthService.getAccessToken
					}
				}),
			TranslateModule.forRoot(
				{
					loader:
					{
						provide: TranslateLoader,
						useFactory: HttpLoaderFactory,
						deps: [HttpClient]
					}
				}),
			ServiceWorkerModule.register('ngsw-worker.js',
				{
					enabled: environment.production,
					registrationStrategy: 'registerWhenStable:30000'
				})
		],
	providers:
		[
			{ provide: LOCALE_ID, useValue: USER_LOCALE },
			{ provide: MATERIAL_SANITY_CHECKS, useValue: false },
			{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },

			GlobalState,
			Title,
			NotificationsService,
			IdentityService,

			// azure ad msal service
			{
				provide: MSAL_INSTANCE,
				useFactory: MSALInstanceFactory
			},
			{
				provide: MSAL_GUARD_CONFIG,
				useValue:
					{
						interactionType: InteractionType.Popup,
						authRequest:
						{
							// mandatory for backend verification
							scopes: ['User.Read', 'User.ReadBasic.All', 'Presence.Read.All']
						}
					} as MsalGuardConfiguration
			},
			MsalService
		],
	bootstrap: [AppComponent]
})

export class AppModule
{
	constructor()
	{

	}
}