
export class PCodeConfig
{
	static azureAd: PCodeConfigAzureAd =
		{
			tenantId: '9e220e66-b958-406e-8dee-9626333d77a5',
			clientId: '5b135524-acdb-459a-9c8d-7abf3c213ff7'
		}

	static vapid: PCodeConfigVapid =
		{
			publicKey: 'BHizkSp85qDkNe3OA_maTi-W3tO5aEPkwaCGDdMrKFyUFNUWM5cBo3ccF3aaQxo_PMheg1b71wixiqWe3YqYlkg'
		}
}

interface PCodeConfigAzureAd
{
	tenantId: string;
	clientId: string;
}
interface PCodeConfigVapid
{
	publicKey: string;
}