import { Injectable, OnInit } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { Claim, IdentityUser } from './identity.models';

@Injectable()
export class IdentityService implements OnInit
{
	private token: string;
	private user: IdentityUser;

	constructor(private jwtHelper: JwtHelperService)
	{
	}

	// init
	ngOnInit()
	{
		// get access token
		this.token = AuthService.getAccessToken();
	}

	// get logged identity user
	getUser(): IdentityUser
	{
		// setting user from token
		if (!this.user)
		{
			// decoding token payload
			const tokenPayload = this.jwtHelper.decodeToken(this.token);

			// loading user info
			this.user = new IdentityUser();
			this.user.id = tokenPayload[Claim.NameIdentifier];
			this.user.username = tokenPayload[Claim.Name];
			this.user.firstName = tokenPayload[Claim.GivenName];
			this.user.lastName = tokenPayload[Claim.Surname];
			this.user.email = tokenPayload[Claim.EmailAddress];
			this.user.sysAdmin = tokenPayload[Claim.SysAdmin] === 'false' ? false : true;
			this.user.language = tokenPayload[Claim.Language];
			this.user.permissions = JSON.parse(tokenPayload[Claim.Permissions]);
		}

		return this.user;
	}

	// verify current user has permission
	hasPermission(alias: string): boolean
	{
		// get current user
		const user: IdentityUser = this.getUser();

		if (!user.sysAdmin)
			return user.permissions.indexOf(alias) >= 0;
		else
			return true;
	}
}