export class IdentityUser
{
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    pushNotifications: boolean;
    inAppNotifications: boolean;
    sysAdmin: boolean;
    language: string;

	permissions: string[];
}

export const enum AuthenticationMode
{
    pCode = 1,
    AzureAD = 2
}

export const enum Claim
{
    // .net core identity claims
    NameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    GivenName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    Surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    EmailAddress = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',

    // custom claims
    Permissions = 'customclaims/permissions',
    SysAdmin = 'customclaims/sysadmin',
    Language = 'customclaims/language'
}